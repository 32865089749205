@import "@moneris-portal/styles/colors.scss";
#user_phone_edit_container {
  font-family: avenir_lt_roman, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  &[role="button"] {
    cursor: auto;
  }

  > .dialog_title {
    margin-bottom: 1rem;
  }

  > .dialog_content {
    > form {
      > p {
        margin-bottom: 1.25rem;
      }
    }
  }

  > .dialog_action {
    > button {
      line-height: 1.5;
      .button_content {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        .spinner {
          box-sizing: border-box;
          border: 4px solid $grey;
          border-top: 4px solid $white;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          animation: spinnerRotate 1s linear infinite;
        }
      }
    }
  }

  .textInput_input {
    > span {
      margin-bottom: 0rem;
      > p {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@keyframes spinnerRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
