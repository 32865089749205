@import "@moneris-portal/styles/colors.scss";

.useful_links_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  line-height: 1.5;

  p {
    margin: 0;
  }

  > .useful_links_set {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .link_container {
      p {
        font-family: "AvenirLTStd-65-Medium";
      }
    }

    > .useful_links_subtitle {
      font-size: 0.875rem;
      color: $midnight_blue;
    }
  }
}
