@import "@moneris-portal/styles/colors.scss";
#verify_container {
  font-family: avenir_lt_medium, sans-serif;

  p {
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  &[role="button"] {
    cursor: auto;
  }

  > .dialog_title {
    margin-bottom: 1rem;
  }

  > .dialog_content {
    .strong {
      font-weight: 700;
    }

    > form {
      > p {
        margin-bottom: 1.25rem;
      }
    }
  }

  .textInput_input {
    > span {
      margin-bottom: 0rem;
      > p {
        margin-bottom: 0.5rem;
      }
    }
  }

  > .dialog_action {
    .button_content {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      .spinner {
        box-sizing: border-box;
        border: 4px solid $grey;
        border-top: 4px solid $white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spinnerRotate 1s linear infinite;
      }
    }
  }
}
