@import "@moneris-portal/styles/colors.scss";
@import "../../../../css/variables.scss";
@import "../../../../css/mdsColours/index.scss";
.security_container {
  .primary_button {
    color: $midnight_blue;
    background-color: $sunset;
    border: 2px solid $sunset;
    max-width: fit-content;

    &:focus,
    &:hover {
      background-color: $pale_sunset;
      border: 2px solid $pale_sunset;
    }

    &[disabled="disabled"],
    &:disabled {
      background-color: $grey !important;
      border: 2px solid $grey !important;
      cursor: default !important;
    }
  }

  .security_content_container {
    .collapsible_container {
      margin-bottom: 2rem;
      min-width: 0rem;

      .collapsible_title {
        color: $midnight_blue;
        font-family: "AvenirLTStd-55-Roman";
        font-size: 1rem;
      }

      .two_factor_authentication_bar {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        > div {
          font-family: "AvenirLTStd-55-Roman";
          font-size: 1rem;
          color: $midnight_blue;
        }
      }

      .password_body {
        .body_row {
          display: flex;
          // flex-direction: column;
          margin-bottom: 1.5rem;

          &.horizontal {
            flex-direction: row;
            gap: 1rem;
          }

          &:last-child {
            margin-bottom: 0rem;
          }

          .body_cell {
            display: flex;
            align-items: center;
            &.one {
              font-family: AvenirLTStd-85-Heavy;
              display: flex;
              gap: 0.5rem;
            }
          }
        }
      }

      .two_fa_body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .radio_button_row {
          display: flex;
          flex-direction: row;
          gap: 2.5rem;
          .radioButton {
            background: $white;
            height: 1rem;
            width: 1rem;
            > label {
              > span {
                flex: none !important;
                &:first-child {
                  width: 0rem;
                  height: 0rem;
                }
                input[type="radio"] {
                  margin-top: 0.2rem;
                }
              }
            }
          }

          > .radio_content {
            display: flex;
            flex-direction: column;
            min-width: 0;
            > div {
              &.add_ellipsis {
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &.title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5rem;
                font-family: AvenirLTStd-85-Heavy;
              }
            }
            button.primary_button {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  .security_container {
    .Toastify__toast-container {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);

      .Toastify__toast {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}
