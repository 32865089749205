@import "@moneris-portal/styles/colors.scss";
@import '../../../../../css/variables.scss';
@import "../../../../../css/mdsColours/index.scss";
#auth_setup_modal {
  font-family: avenir_lt_medium, sans-serif;
  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  &[role="button"] {
    cursor: auto;
  }

  > .dialog_title {
    margin-bottom: 1rem;
  }

  .qr_code {
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 1.1rem;
    font-weight: 700;
    color: $midnight_blue;
  }

  .step_container {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > * {
      margin: 0rem;
    }

    .qr_code_button_row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      .primary_button {
        background: #00b6c9;
        border: 2px solid #00b6c9;
        color: $midnight_blue;

        &:focus,
        &:hover {
          background-color: #92d2e7;
          border: 2px solid #92d2e7;
        }

        &[disabled="disabled"],
        &:disabled {
          background-color: $grey !important;
          border: 2px solid $grey !important;
          cursor: default !important;
        }
      }
    }

    .qr_code {
      display: flex;
      justify-content: center;
      > .spinner {
        box-sizing: border-box;
        border: 4px solid $white;
        border-top: 4px solid $midnight_blue;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spinnerRotate 1s linear infinite;
      }
    }

    &:last-child {
      margin-bottom: 0rem;
      .textInput_input {
        margin-bottom: 0rem;
      }
    }

    p {
      &:last-child {
        margin-bottom: 0rem;
      }
    }

    .textInput_input {
      margin-bottom: 1rem;
      #_hidden {
        height: 0rem;
      }
    }
  }

  > .dialog_action {
    > button {
      line-height: 1.5;
      .button_content {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        .spinner {
          box-sizing: border-box;
          border: 4px solid $grey;
          border-top: 4px solid $white;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          animation: spinnerRotate 1s linear infinite;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  #auth_setup_modal {
    .step_container {
      .qr_code_button_row {
        flex-direction: column;
        gap: 0rem;
      }
    }
  }
}

@keyframes spinnerRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
