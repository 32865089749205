@import "../../css/mdsColours/index.scss";
@import "../../css/variables.scss";
@import "@moneris-portal/styles/colors.scss";
#page_layout {
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    width: 100vw;

    > h3 {
      font-family: AvenirLTStd-95-Black;
      color: $midnight_blue;
      text-align: left;
      margin: 1.8125rem auto 1rem auto;
      max-width: 1440px;
      padding: 1rem;
      width: 100%;
    }

    #page_layout_body {
      background-color: $alice_blue;
      h4 {
        font-family: AvenirLTStd-95-Black;
        font-size: 1.125rem;
        color: $midnight_blue;
        margin: 1.8125rem auto 1rem auto;
        max-width: 1440px;
        padding-left: 1rem;
        text-align: left;
      }

      .app_card_grid {
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        margin: 0rem auto 2rem auto;
        max-width: 1440px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .section_row {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1440px;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 1rem auto 2rem auto;

      .useful_links_block {
        width: 30%;
        h3 {
          margin: 0 0 1.5rem 0;
          font-weight: 750;
          font-size: 1.125rem;
          line-height: 1.625rem;
          color: $midnight_blue;
        }
      }
    }
  }
}

@media screen and (max-width: 1110px) {
  #page_layout {
    > div {
      .section_row {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  #page_layout {
    > div {
      > h3 {
        padding-left: 0rem;
        text-align: center;
      }

      .section_row {
        .useful_links_block {
          width: 100%;
        }
      }
    }
  }
}
