@import "@moneris-portal/styles/colors.scss";
@import "../../../css/variables.scss";
.resource_card_section {
  width: 67%;

  h3 {
    margin: 0 0 1.5rem 0;
    font-weight: 750;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: $midnight_blue;
  }

  p {
    margin: 0;
  }

  .resource_cards {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
}

@media screen and (max-width: $mobile_screen) {
  .resource_card_section {
    width: 100%;
    .resource_cards {
      flex-direction: column;
    }
  }
}
