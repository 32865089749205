/* Consolidation of frequently used colours throughout the repo */

// fonts
  $midnight_blue: #051D33; // text
  $dark_red: #B01A0C; // required fields astrisks|important text
  $faded_grey: #6B6B6B; // placeholders

// background/icons
  $white: #FFFFFF; // plain white
  $light_grey: #EEEEEE; // lighter grey?
  $grey: #C3C3C3; // light grey
  $teal: #6DC7BE; // moneris teal|active/in-focus
  $dark_teal: #00A7B5; // reset link/filter
  $shadow_grey: #AEAEB7; // card border shadow
  $dark_cyan: #00677f; //border on hover
  $blue: #007bff; //background on hover for dropdown
  $WhiteLight: #fafafa;
  $meldNeutral: #686C6D; // agent typing background
  $loaderBackground : #afa7a785;
  $deep : #024055;
  $light_cyan: #F3FAFD;
  $sunrise: #F7BF08;
  $popupBorder:#4C5055;
  $popupBoxShadow:#00000040;
  $sunset_light: #FFD877;
  $sky: #00B6C9;
  $alice_blue: #F3FAFD;
