@import "../../css/mdsColours/index.scss";

.monerisHeader {
  background-color: $midnight_blue !important;
  padding: 6px 1.25rem;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  max-height: 48px;
  height: 48px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.justifyContentEnd {
  justify-content: flex-end;
  padding-right: 10px;
}

.smallLogo {
  width: 110px;
  height: 35px;
  fill: #fff;
}

.headerLeftContent {
  display: flex;
  flex: 6;
  cursor: pointer;
}

.headerRightContent {
  margin-left: 2rem;
  margin-right: 0.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  > .header_nav_container > .header_nav_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      // using webkit box to dynamically add ellipsis if screen gets narrower
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-height: 1.43;
    }
    > svg {
      // avoid icon resize when container reduce in size
      flex-shrink: 0;
    }
  }
}

.helpText {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  font-family: avenir_lt_roman, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  .helpIcon {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    display: inline-block;
  }
  a {
    color: #ffffff;
  }
  a:active,
  a:hover {
    color: #1da897;
  }
}

.merchantLogo {
  width: 150px;
  height: 35px;
  padding-top: 12px;
}

.dropdownIcon {
  width: 15px;
  height: 15px;
}

.navItemAlignMiddle {
  white-space: nowrap;
}

.navItemAlignMiddle:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.navItemAlignMiddle > * {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  color: #fff !important;
  font-size: 1rem;
}

.navLink span {
  padding-left: 0.5rem;
}

.smallMenu {
  min-width: 5rem;
  background: #00294d;
  color: #fff !important;
  right: 0;
  border: none;
}

.smallMenu > * {
  color: #fff !important;
}

.smallMenu > *:active,
.smallMenu > *:hover,
.smallMenu > *:focus {
  background-color: transparent;
}

/* Remove the button style
 * and
 * down arrow via CSS hack as component doesn't do that
 */
.noNavLinkStyle,
.noNavLinkStyle:hover {
  background-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.noNavLinkStyle::after {
  display: none;
}
