.page_error--container{
    .page_error--body{
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-family: 'avenir_lt_roman';
        p{
            padding: 1rem;
            font-size: 1.2rem;
        };
        button{
            width: 10rem;
            margin: 1rem auto;
        }
    }
}