@import "@moneris-portal/styles/colors.scss";
@import "../../../../css/variables.scss";
.user_profile_container {
  .user_profile_content_container {
    .collapsible_container {
      margin-bottom: 2rem;
      min-width: 0rem;

      .user_profile_title {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        margin-right: 1rem;

        > span {
          font-size: 1rem;
          font-family: "AvenirLTStd-55-Roman";
          color: $midnight_blue;
        }

        > .user_profile_button {
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          color: #007191;

          display: flex;
          gap: 0.5rem;

          &:hover {
            cursor: pointer;
          }

          &.disabled {
            cursor: default;
            color: $grey;
          }
        }
      }

      .user_profile_container_body {
        color: $midnight_blue;
        display: flex;
        flex-direction: column;
        .body_row {
          display: flex;
          margin-bottom: 1.5rem;
          align-items: flex-start;
          flex-direction: column;
          text-align: left;
          font-size: 1rem;

          &:last-child {
            margin-bottom: 0rem;
          }

          .body_cell {
            flex-grow: 1;
            padding: 0rem 0rem 0rem 1rem;

            > div {
              overflow-wrap: anywhere;
            }

            &.one {
              font-family: AvenirLTStd-85-Heavy;
            }

            &.two {
              &.cap {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  .user_profile_container {
    .Toastify__toast-container {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);

      .Toastify__toast {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}
