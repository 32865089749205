.header_nav_container {
  position: relative;
  .header_nav_main {
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    text-transform: capitalize;
    span {
      padding-right: 0.5rem;
    }
  }

  .header_nav_options {
    position: absolute;
    // background-color: #ffffff;
    border-radius: 0.25rem;
    line-height: 1.618;
    font-size: 1rem;
    color: #f3fafd;
    background-color: #1b3b58;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    border: 1px solid #c3c3c3;
    min-width: 50px;
    right: -5px;
    top: 29px;
    text-align: right;
    padding: 1rem;
    ul {
      text-align: left;
      list-style: none;
      padding-left: 0.2rem;
      margin-bottom: 0rem !important;
      li {
        cursor: pointer;
      }
      li:active {
        background: #1b3b58;
      }
    }
    .header_nav_overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      z-index: -1;
    }
  }
}
