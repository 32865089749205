@import "../../../css/variables.scss";
@import "../../../css/mdsColours/index.scss";

.application_card_container {
  padding: 2rem;
  min-width: 30rem;
  max-width: calc(50% - 2rem);
  border: 1px solid #eee;
  border-top: 5px solid $sky;
  background-color: $white;

  &:hover {
    border-top: 5px solid $sky;
    background-color: #f3fafd;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  &[disabled] {
    background-color: #eeeeee;
    cursor: not-allowed;
  }

  >.application_card_body {
    display: flex;
    height: 100%;

    .cardIcon {
      text-align: center;
      padding-right: 0.5rem;
      padding: 2rem 2rem 0rem 1rem;

      >img {
        height: 7.25rem;
        width: 7.25rem;
        object-fit: cover;

      }

      &.wide {
        >img {
          width: 8.25rem;
        }

      }
    }

    .card_desc {
      text-align: left;
      height: 100%;
      display: flex;
      flex-direction: column;

      .app_description {

        p,
        li {
          line-height: 1.5;
        }
      }

      ul {
        list-style: disc;
        list-style-position: inside;
      }

      .cardTitle {
        text-align: left;
        padding: 0rem 1rem 1rem 0rem;

        font-family: "AvenirLTStd-95-Black";
        letter-spacing: 0;
        color: #051d33;
        margin: 0;
        font-weight: 750;
        font-size: 1.125rem;
        line-height: 1.625rem;
      }

      ._card_buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        margin-top: auto;

        button {
          margin: 1rem 0rem;

          &.primary_button {
            background-color: $sunrise;
            color: $midnight_blue;
          }

          >span {
            line-height: 1.5;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  .application_card_container {
    min-width: 0;
    max-width: none;

    >.application_card_body {
      .cardIcon {
        display: none;
      }

      .card_desc {
        ._card_buttons {
          display: flex;
          flex-direction: column-reverse;

          button {
            margin: 0rem;
            max-width: none;
          }
        }
      }
    }
  }
}