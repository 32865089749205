@import '../../css/variables.scss';
#setting-container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  min-width: 0rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  > .lower_part {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0rem 2rem;
    column-gap: 2rem;
    .responsive_navigationtab_container {
      max-width: 78.125rem;
      min-width: 0rem;
      max-height: none;
      flex-grow: 1;

      .responsive_navigationtab {
        flex-grow: 0;
        ul {
          margin-bottom: 0rem;
          li {
            line-height: 1;
            height: min-content;
            &:last-child {
              border-radius: 0rem 0.5rem 0.5rem 0rem;
            }

            &:first-child {
              border-radius: 0.5rem 0rem 0rem 0.5rem;
            }
          }
        }
      }

      .responsive_navigationtab_body {
        padding: 0rem;
        margin: 0rem;
      }
    }

    .statement_navigation {
      position: inherit;
      width: min-content;
      height: min-content;
      > ul {
        margin-bottom: 0rem;
      }
    }
  }

  > .upper_part {
    width: 100%;
    .page_header {
      margin: 1.6875rem 2rem 1.5rem 2rem;
      .link_text {
        font-family: "AvenirLTStd-55-Roman";
        margin-bottom: 0rem;
        white-space: nowrap;
      }
      .page_title {
        .header_1 {
          font-weight: 750;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  #setting-container {
    width: 100%;
    > .lower_part {
      width: 100%;
      display: inherit;
      .statement_navigation {
        display: none;
      }
    }
    > .upper_part {
      .page_header {
        margin-bottom: 0.5rem;
      }
    }
  }
}
