@import "@moneris-portal/styles/colors.scss";
@import "@moneris-portal/styles/zIndex.scss";
@import '../../css/variables.scss';
.moneris_footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: $FOOTER_ZINDEX;
  background-color: $midnight_blue;
  padding: 1rem 0 1.125rem;
  width: 100%;

  > a {
    padding: 0 1rem;
    border-right: 1px solid $white;
    color: $white;
    text-decoration: none;
    line-height: 1.5;

    &:hover {
      text-decoration: underline;
      color: $white;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: $mobile_screen) {
  .moneris_footer {
    flex-direction: column;
    position: inherit;
    align-items: center;
    &.sticky_footer {
      position: static;
    }

    > a {
      padding: 1rem 0;
      border-bottom: 1px solid $white;
      border-right: none;

      &:first-child {
        padding-top: 1.5rem;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 1.5rem;
      }
    }
  }
}
