@import "../../css/mdsColours/index.scss";

#setting-step {
  position: fixed;
  top: 3rem;
  bottom: 3rem;
  left: 0em;
  right: 0rem;
  background: #fff;
  z-index: 10001;
  color: $midnight_blue;
  background-image: url('../../assets/moneris_logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  > div {
    border: 1px solid #eee;
    margin: 1rem;
    padding: 4rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 15%;
      h3 {
        color: $midnight_blue !important;
        margin-bottom: 1rem;
      }
      > p:nth-child(3) {
        font-weight: bold;
        padding-top: 1rem;
        margin-bottom: 4rem;
      }
      > button {
        max-width: 10rem;
      }
    }
    > p {
      position: absolute;
      bottom: 3rem;
      right: 5%;
      text-decoration: underline;
      font-size: 1rem;
      cursor: pointer;
    }
  }
}
