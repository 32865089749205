.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

body iframe {
  z-index: -1000000000000000 !important;
}


#main-content {
  margin-top: 3rem;
  min-height: calc(100vh - 6.625rem);
}

.radioButton label input:checked {
  background-image: url("../assets/radiobuttonBackground.svg") !important;
}

@font-face {
  font-family: "AvenirLTStd-95-Black-Oblique";
  src: url("../fonts/AvenirLTStd-95-Black-Oblique.woff") format("woff");
}

@font-face {
  font-family: "AvenirLTStd-95-Black";
  src: url("../fonts/AvenirLTStd-95-Black.woff") format("woff");
}

@font-face {
  font-family: "AvenirLTStd-85-Heavy-Oblique";
  src: url("../fonts/AvenirLTStd-85-Heavy-Oblique.woff") format("woff");
}

@font-face {
  font-family: "AvenirLTStd-85-Heavy";
  src: url("../fonts/AvenirLTStd-85-Heavy.woff") format("woff");
}

@font-face {
  font-family: "AvenirLTStd-55-Oblique";
  src: url("../fonts/AvenirLTStd-55-Oblique.woff") format("woff");
}

@font-face {
  font-family: "AvenirLTStd-55-Roman";
  src: url("../fonts/AvenirLTStd-55-Roman.woff") format("woff");
}

@font-face {
  font-family: "AvenirLTStd-65-Medium-Oblique";
  src: url("../fonts/AvenirLTStd-65-Medium-Oblique.woff") format("woff");
}

@font-face {
  font-family: "AvenirLTStd-65-Medium";
  src: url("../fonts/AvenirLTStd-65-Medium.woff") format("woff");
}


/* body button:active {
	text-decoration: underline !important;
} */