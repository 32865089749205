@import "@moneris-portal/styles/colors.scss";
@import "../../../css/variables.scss";

.resource_card_container {
  width: 19rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.resource_card_image {
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 8/5;
}

.resource_card_content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.resource_card_title {
  font-family: "AvenirLTStd-65-Medium" !important;
  margin: 0 !important;
  font-size: 1rem !important;
}

.resource_card_children {
  font-size: 0.875rem;
  line-height: 1.5rem;
  flex: 1 1 auto;
  color: $midnight_blue;
}

.resource_card_link_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: 550;
  margin-top: 0.625rem;
  gap: 12.75px;
  cursor: pointer;
}

.resource_card_link {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: $mobile_screen) {
  .resource_card_container {
    width: 100%;
  }
}
