.sign-up--container {
  .sign-up--body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: avenir_lt_roman, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    p {
      padding: 1rem;
      font-size: 1.2rem;
    }
    button {
      width: 10rem;
      margin: 1rem auto;
    }
  }
}
