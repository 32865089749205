#launch_app_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  button {
    max-width: 10rem;
    margin: 5rem auto;
  }
  .notification {
    max-width: 27rem;
  }
}
